<template>
  <div>
    <div v-for="fieldsGroup of fieldsGroups" :key="fieldsGroup.id">
      <div>
        <div class="field-group-header">
          <b-row>
            <b-col cols="9">
              <b>{{ fieldsGroup.name }}</b>
            </b-col>
          </b-row>
        </div>
        <div v-for="field in fieldsGroup.fields" v-bind:key="field.id" class="field-line">
          <b-form-group
            :id="'field-group-' + field.id"
            :label="checkboxField(field) ? '' : (field.text + (field.isMandatory ? '*' : ''))"
            :label-for="'field-' + field.id"
            :description="field.helpText"
          >
            <b-form-select
              :id="'field' + field.id"
              v-model="fieldsData[field.id]"
              v-if="selectField(field)"
              :required="fieldsData.isMandatory"
              :rel="fieldsData[field.id]"
              @change="fieldValueChanged()"
              :disabled="!canChange(fieldsGroup)"
            >
              <b-form-select-option :value="choice.id" v-for="choice in field.choices" v-bind:key="choice.id">
                {{ choice.text }}
              </b-form-select-option>
            </b-form-select>
            <b-form-input
              v-if="textField(field)"
              :id="'field' + field.id"
              v-model="fieldsData[field.id]"
              :required="fieldsData.isMandatory"
              type="text"
              @change="fieldValueChanged()"
              :disabled="!canChange(fieldsGroup)"
            ></b-form-input>
            <vue-bootstrap-typeahead
              v-if="typeaheadField(field)"
              :id="'field' + field.id"
              v-model="fieldsData[field.id]"
              :data="getSuggestions(field.id)"
              :ref="'fieldTypeAhead' + field.id"
              :required="fieldsData.isMandatory"
              @change="fieldValueChanged()"
              :disabled="!canChange(fieldsGroup)"
            />
            <b-form-checkbox
              v-if="checkboxField(field)"
              :id="'field' + field.id"
              v-model="fieldsData[field.id]"
              @change="fieldValueChanged()"
              :name="'field' + field.id"
              :value="true"
              :unchecked-value="false"
              :disabled="!canChange(fieldsGroup)"
            >
              {{ field.text }}
            </b-form-checkbox>
            <b-form-input
              v-if="dateField(field)"
              :id="'field' + field.id"
              v-model="fieldsData[field.id]"
              :required="fieldsData.isMandatory"
              type="date"
            ></b-form-input>
            <b-form-input
              v-if="intField(field)"
              :id="'field' + field.id"
              v-model="fieldsData[field.id]"
              :required="fieldsData.isMandatory"
              step="1"
              type="number"
            ></b-form-input>
            <decimal-input
              v-if="decimalField(field)"
              :id="'field' + field.id"
              v-model="fieldsData[field.id]"
              :required="fieldsData.isMandatory"
            ></decimal-input>
          </b-form-group>
          <b-form-group
            :id="'comments-group-' + field.id"
            label="Commentaires"
            :label-for="'comments' + field.id"
            v-if="field.allowComments"
          >
            <b-form-input
              :id="'comments' + field.id"
              v-model="commentsData[field.id]"
              type="text"
              :disabled="!canChange(fieldsGroup)"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DecimalInput from '@/components/Controls/DecimalInput.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import {
  APPLY_TO_INDIVIDUALS, APPLY_TO_ADULTS, APPLY_TO_CHILDREN, APPLY_TO_ENTITIES, APPLY_TO_FAMILIES,
  APPLY_TO_ORGANIZATIONS, APPLY_TO_ENTITY_MEMBERS, FieldType
} from '@/types/fields'

export default {
  name: 'fields-input',
  components: { DecimalInput, },
  mixins: [BackendMixin],
  props: {
    individual: {
      type: Object,
      default: null,
    },
    entity: {
      type: Object,
      default: null,
    },
    fieldsGroups: Array,
    fieldValues: Object,
    readOnly: Boolean,
  },
  data() {
    return {
      fieldsData: {},
      commentsData: {},
      suggestions: {},
    }
  },
  mounted() {
    this.onEdit()
  },
  computed: {
  },
  watch: {
    fieldsGroups: function() { this.onEdit() },
    fieldValues: function() { this.onEdit() },
  },
  methods: {
    fieldValueChanged() {
      this.$emit('change', { fieldsData: this.fieldsData, commentsData: this.commentsData, })
    },
    canView(fieldsGroup) {
      if (!(this.hasPerm('fields.view_field'))) {
        return false
      }
      if (fieldsGroup.applyTo.indexOf(APPLY_TO_INDIVIDUALS) >= 0) {
        return this.hasPerm('people.view_individual')
      } else if (fieldsGroup.applyTo.indexOf(APPLY_TO_ADULTS) >= 0) {
        return this.hasPerm('people.view_individual')
      } else if (fieldsGroup.applyTo.indexOf(APPLY_TO_CHILDREN) >= 0) {
        return this.hasPerm('families.view_child')
      } else if (fieldsGroup.applyTo.indexOf(APPLY_TO_ENTITY_MEMBERS) >= 0) {
        return this.hasPerm('people.view_individual')
      } else if (fieldsGroup.applyTo.indexOf(APPLY_TO_ENTITIES) >= 0) {
        return this.hasOnePerm(['people.view_contact', 'families.view_familyentity'])
      } else if (fieldsGroup.applyTo.indexOf(APPLY_TO_FAMILIES) >= 0) {
        return this.hasPerm('families.view_familyentity')
      } else if (fieldsGroup.applyTo.indexOf(APPLY_TO_ORGANIZATIONS) >= 0) {
        return this.hasPerm('people.view_contact')
      }
    },
    canChange(fieldsGroup) {
      if (this.readOnly) {
        return false
      }
      if (!(this.hasPerm('fields.view_field'))) {
        return false
      }
      if (fieldsGroup.applyTo.indexOf(APPLY_TO_INDIVIDUALS) >= 0) {
        return this.hasPerm('people.change_individual')
      } else if (fieldsGroup.applyTo.indexOf(APPLY_TO_ADULTS) >= 0) {
        return this.hasPerm('people.change_individual')
      } else if (fieldsGroup.applyTo.indexOf(APPLY_TO_CHILDREN) >= 0) {
        return this.hasPerm('families.change_child')
      } else if (fieldsGroup.applyTo.indexOf(APPLY_TO_ENTITY_MEMBERS) >= 0) {
        return this.hasPerm('people.change_individual')
      } else if (fieldsGroup.applyTo.indexOf(APPLY_TO_ENTITIES) >= 0) {
        return this.hasOnePerm(['people.change_contact', 'families.change_familyentity'])
      } else if (fieldsGroup.applyTo.indexOf(APPLY_TO_FAMILIES) >= 0) {
        return this.hasPerm('families.change_familyentity')
      } else if (fieldsGroup.applyTo.indexOf(APPLY_TO_ORGANIZATIONS) >= 0) {
        return this.hasPerm('people.change_contact')
      }
    },
    onEdit() {
      if (this.fieldsGroups) {
        this.fieldsData = this.initializeFieldsData(this.fieldsGroups)
        this.commentsData = this.initializeCommentsData(this.fieldsGroups)
        for (const fieldGroup of this.fieldsGroups) {
          for (let field of fieldGroup.fields) {
            if (field.allowSuggestions) {
              this.loadSuggestions(field)
              setTimeout(
                () => {
                  let value = this.fieldsData[field.id]
                  let ref = 'fieldTypeAhead' + field.id
                  this.$refs[ref][0].inputValue = value
                },
                100
              )
            }
          }
        }
        this.fieldValueChanged()
      }
    },
    async getSuggestions(field) {
      return this.suggestions[field.id] || []
    },
    getText(field) {
      if (this.fieldValues) {
        return this.fieldValues.getText(field)
      }
      return ''
    },
    getValue(field) {
      let value = ''
      let valueFound = false
      if (this.fieldValues) {
        value = this.fieldValues.getValue(field)
        valueFound = (value !== null)
      }
      if (!valueFound) {
        if (field.fieldType === FieldType.Choices) {
          const defaultChoices = (field.choices.filter(elt => elt.isDefault))
          if (defaultChoices.length) {
            value = defaultChoices[0].id
          }
        }
        if (field.fieldType === FieldType.Boolean) {
          if (field.initTrue) {
            value = true
          }
        }
      }
      return value
    },
    getComments(field) {
      if (this.fieldValues) {
        return this.fieldValues.getComments(field)
      }
      return ''
    },
    initializeFieldsData(fieldsGroups) {
      let data = {}
      for (const group of fieldsGroups) {
        for (let field of group.fields) {
          data[field.id] = this.getValue(field)
        }
      }
      return data
    },
    initializeCommentsData(fieldsGroups) {
      let data = {}
      for (const group of fieldsGroups) {
        for (let field of group.fields) {
          data[field.id] = this.getComments(field)
        }
      }
      return data
    },
    textField(field) {
      return (field.fieldType === FieldType.Text) && (!field.allowSuggestions)
    },
    typeaheadField(field) {
      return (field.fieldType === FieldType.Text) && (field.allowSuggestions)
    },
    selectField(field) {
      return field.fieldType === FieldType.Choices
    },
    checkboxField(field) {
      return field.fieldType === FieldType.Boolean
    },
    dateField(field) {
      return (field.fieldType === FieldType.Date)
    },
    intField(field) {
      return (field.fieldType === FieldType.Integer)
    },
    decimalField(field) {
      return (field.fieldType === FieldType.Decimal)
    },
    async loadSuggestions(field) {
      let url = ''
      if (this.individual) {
        url = '/api/people/individual-values-suggestions/' + field.id + '/'
      }
      if (this.entity) {
        url = '/api/people/entity-values-suggestions/' + field.id + '/'
      }
      if (url) {
        const backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.suggestions[field.id] = resp.data
          this.suggestions = { ...this.suggestions, }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.field-comments {
  background: #ccc;
  margin-top: 2px;
  padding: 5px;
}
</style>
